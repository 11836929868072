@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: 'Druk-Bold';
	src: url('./assets/fonts/Druk_Text_Wide/DrukTextWide-Bold-Trial.otf');
}
@font-face {
	font-family: 'Druk-BoldItalic';
	src: url('./assets/fonts/Druk_Text_Wide/DrukTextWide-BoldItalic-Trial.otf');
}
@font-face {
	font-family: 'Druk-Heavy';
	src: url('./assets/fonts/Druk_Text_Wide/DrukTextWide-Heavy-Trial.otf');
}
@font-face {
	font-family: 'Druk-HeavyItalic';
	src: url('./assets/fonts/Druk_Text_Wide/DrukTextWide-HeavyItalic-Trial.otf');
}
@font-face {
	font-family: 'Druk-Medium';
	src: url('./assets/fonts/Druk_Text_Wide/DrukTextWide-Medium-Trial.otf');
}
@font-face {
	font-family: 'Druk-Medium-Italic';
	src: url('./assets/fonts/Druk_Text_Wide/DrukTextWide-MediumItalic-Trial.otf');
}
@font-face {
	font-family: 'Druk-Super';
	src: url('./assets/fonts/Druk_Text_Wide/DrukTextWide-Super-Trial.otf');
}
@font-face {
	font-family: 'Druk-Super-Italic';
	src: url('./assets/fonts/Druk_Text_Wide/DrukTextWide-SuperItalic-Trial.otf');
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,900;1,400&display=swap');

* {
	box-sizing: border-box;
}

:root {
	font-family: Duke Wide, Inter, Avenir, Helvetica, Arial, sans-serif;

	/* color-scheme: light dark; */
	color: #e9ebf1;
	background-color: #060606;
	scrollbar-width: none;

	font-synthesis: none;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-text-size-adjust: 100%;

	--color-green-basic: #515934;
	--color-green-shade1: #48502e;
	--color-green-shade2: #404729;
	--color-green-shade3: #383e24;

	--color-black-basic: #010d00;
	--color-black-tint1: #000b00;
	--color-black-tint2: #000a00;
	--color-black-tint13: #000900;

	--color-greenblue-basic: #2a5559;
	--color-greenblue-shade1: #254c50;
	--color-greenblue-shade2: #214447;
	--color-greenblue-shade3: #1d3b3e;

	--color-lightgray-basic: #ccd5d9;
	--color-lightgray-shade1: #b7bfc3;
	--color-lightgray-shade2: #a3aaad;
	--color-lightgray-shade3: #8e9597;

	--color-normalgray-basic: #92a0a6;
	--color-normalgray-shade1: #839095;
	--color-normalgray-shade2: #748084;
	--color-normalgray-shade3: #667074;

	--color-primary-900: #0353a4;
}

html { 
	scroll-behavior: smooth;
	background-color: #060606;
}

body {
	margin: 0;
	font-family: "Duke Wide";
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	margin-left: auto;
	margin-right: auto;
}

ul {
	list-style: none;
	margin: 0;
	padding: 0;
}


.poppins-light {
	font-family: "Poppins", serif;
	font-weight: 300;
	font-style: normal;
}

.poppins-regular {
	font-family: "Poppins", serif;
	font-weight: 400;
	font-style: normal;
}

.poppins-medium {
	font-family: "Poppins", serif;
	font-weight: 500;
	font-style: normal;
}

.poppins-semibold {
	font-family: "Poppins", serif;
	font-weight: 600;
	font-style: normal;
}

.poppins-italic {
	font-family: "Poppins", serif;
	font-weight: 400;
	font-style: italic;
}

.poppins-black {
	font-family: "Poppins", serif;
	font-weight: 900;
	font-style: normal;
}
