.nav-bar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: transparent;
	position: fixed;
	z-index: 100;
	width: 100%;
	margin-top: 30px;
}

.nav-container {
	position: fixed;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	border: 1px solid transparent;
	border-radius: 25px;
	background-color: hwb(0 27% 72% / 0.752);
	padding: 8px 35px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 30px;
	gap: 60px;
	> a, button {
	  transition: color 0.2s ease-in-out, gap 0.3s ease-in-out;
	  color: #d4d4d4;
	  &:hover {
		color: white;
	  }
	}
	> a.active {
	  color: white;
	}

	@media screen and (max-width: 768px) {
		width: 80%;
		padding: 8px 20px;
	}
}

.nav-container.mobile {
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	width: 80%;
	gap: 30px;
}


.nav-links {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 40px;
	> a, button {
		transition: color 0.2s ease-in-out;
		color: #d4d4d4;
		&:hover {
		  color: white;
		}
	  }
}

.mobile-nav-links {
	display: flex;
	flex-direction: column;
	gap: 15px;
	align-items: flex-start;
	width: 100%;
	> a, button {
		transition: color 0.2s ease-in-out;
		color: #d4d4d4;
		width: 100%;
		text-align: start;
		padding-bottom: 8px;
		&:hover {
		  color: white;
		}
	}
}

.logo {
	max-width: 2rem;
	max-height: 2rem;
	transition: all 150ms ease;

	> g {
		fill: #8bc3fe;
	  }
	@media screen and (max-width: 768px) {
		max-width: 2rem;
		max-height: 2rem;
	}
}